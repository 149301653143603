body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  */
  font-family: 'Epilogue', sans-serif;
  background-color: rgb(22, 22, 22);
}



.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.abt-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.title {
  padding-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-text {
  font-size: 1.3rem;
  font-weight: 400;
  color: rgb(155, 155, 155);
  padding-top: 1em;
}

.name-text {
  font-weight: 600;
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 3.5em;
  padding-top: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-text {
  padding-top: 2em;
  color: whitesmoke;
  font-size: 1.3rem;
}

.grey-text {
  color: rgb(153, 153, 153);
}

.header {
  font-size: 2.3em;
  color: whitesmoke;
  text-align: center;
  padding-bottom: 2em;
}

.crossed-text {
  color: grey;
  text-decoration: line-through;
}

.title-icon {
  padding-left: 13px;
  padding-bottom: 2rem;
  height: 6rem;
  width: 6rem;
}

a {
  color: whitesmoke;
  text-decoration: none;
}

.btn-container {
  padding-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

}

.aboutme-btn {
  color: whitesmoke;
  font-size: 1rem;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 3rem;
}



.contact-btn {
  color:  rgb(22, 22, 22);
  font-size: 1rem;
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 3px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}


.aboutme-wrapper {
  margin: 0 auto;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}





.aboutme-title {
  color: grey;
  font-size: 3rem;
  padding-bottom: 2rem;
  text-align: center;
}


.tech-container {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tech-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

}

.btn-pos-wrapper {
  text-align: center;
}


.tech-container {
  padding-top: 3rem;
  color: whitesmoke;
}


.tech-item {
  padding: 3px;
  color: grey;
}

.white-item {
  color: whitesmoke;
}

.aboutme-text {
  color: whitesmoke;
  text-align: center;
}

#abt-typed-text {
  color: grey;
}

.grey {
  color: grey;
}


.contact-container {
  margin: 0 auto;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.contact-title {
  color: grey;
  font-size: 3rem;
  padding-bottom: 2rem;
  text-align: center;
}


.contact-text {
  color: whitesmoke;

}

.resume-link {
  padding-top: 2rem;
 color: grey;
}


.dashed-border {
  border-bottom: 1px dashed rgb(73, 73, 73);
  transition: border-bottom .2s ease-in;
}

.dashed-border:hover {
  border-bottom: 1px dashed rgb(141, 141, 141);
}



.resume {
  font-size: 1.3rem;
  color: whitesmoke;
  border-bottom: 1px dashed rgb(73, 73, 73);
  transition: border-bottom .2s ease-in;
}

.resume:hover {
  border-bottom: 1px dashed rgb(141, 141, 141);
}


.lt-btn-container {
  padding-top: 3rem;
}

.lt-btn {
  background-color: rgb(22, 22, 22);
  color: whitesmoke;
  font-size: 1rem;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 3rem;
  width: 100%;
  cursor: pointer;
}

.links-container {
  padding-top: 2rem;

}


.link-icon {
  height: 2rem;
  width: 2rem;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}


.back-btn-wrapper {
  padding-top: 1rem;
  padding-left: 3rem;
  cursor: pointer;
  border: 0;
  background-color: inherit;

}


.back-btn-wrapper:focus {
  outline: none
}

.back-btn-wrapper:active {
  outline: none;
}

.back-btn {
  height: 2rem;
  width: 2rem;
  color: whitesmoke;
}


@media screen and (max-width: 768px) {



  .contact-container {
    padding-top: 2rem;
  }

  .aboutme-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
  }

  .title {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-text {
    font-size: 1rem;
  }

  .name-text {
    font-size: 3em;
  }

  .header-text {
    font-size: 1rem;
  }

  .aboutme-btn {

    margin-right: 1.5rem;
  }


  .aboutme-title {
    font-size: 2rem;
  }

  .tech-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*padding-right: 3rem;*/
  }

  .btn-pos-wrapper {
    padding-top: 3rem;
  }

  .contact-container {
    width: 90%;
  }
  
  .contact-title {
    font-size: 2rem;
  }

  .back-btn-wrapper {
    padding-left: 0.5rem;
    padding-top: 1rem;
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
